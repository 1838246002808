a {
  cursor: pointer;
}
a:visited,
button:visited,
a:focus,
button:focus,
a:active,
button:active {
  border: 0 !important;
  box-shadow: none !important;
  outline: none !important;
}
html {
  box-sizing: border-box;
}
ol {
  counter-reset: item;
  > li {
    display: block;

    &:before {
      content: counters(item, '.') ' ';
      counter-increment: item;
    }
  }
}

table > tbody > tr > td:first-child,
table > thead > tr:first-child > th:first-child {
  width: 40px;
}

table.send-files-table > tbody > tr > td:last-child,
table.send-files-table > thead > tr:last-child > th:last-child {
  width: 110px;
}

//reset bootstrap class
.tooltip {
  .tooltip-inner {
    border-radius: 0;
    background-color: #393939;
    padding: 20px;
  }
  .arrow::before {
    color: #393939;
    opacity: 0.7;
  }
}
button.btn-link {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
.card-body {
  padding: 0.5rem;
}
//reset react-phone-number-input class
.PhoneInputInput,
.PhoneInputInput--focus {
  border: none !important;
  outline: 0px !important;
}

.react-images__pager {
  position: unset !important;
}

@media (max-width: 500px) {
  .table-responsive {
    margin-bottom: 1rem;
  }
  .table-responsive::-webkit-scrollbar {
    width: 12px;
    height: 10px;
    color: var(--corAnalogaClara);
  }

  .table-responsive::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: var(--corFundoCinza);
  }

  .table-responsive::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: var(--corAnalogaClara);
  }

  .table {
    font-size: 0.75em;
    margin-bottom: 3px;
  }
  .table tr th,
  .table tr td {
    padding: 5px 3px;
  }
}
.close {
  font-size: 2.3rem;
  font-family: Arial;
  font-weight: normal;
}

/**
 * NProgress
 */
/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background: var(--corPrincipal);

  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;

  width: 100%;
  height: 2px;
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px var(--corPrincipal), 0 0 5px var(--corPrincipal);
  opacity: 1;

  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  left: 15px;
}

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;

  border: solid 2px transparent;
  border-top-color: var(--corPrincipal);
  border-left-color: var(--corPrincipal);
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}

.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.nav-pills > div > a.nav-link.active {
  background-color: transparent;
  border-bottom: 1px solid var(--corPrincipal) !important;
  font-weight: bold;
  color: var(--corPrincipal);
}
.page-link,
.page-link:hover,
.page-link:focus,
.page-link:visited {
  color: var(--corPrincipal);
  border: 1px solid var(--corPrincipal);
}
.page-item.active .page-link {
  background-color: var(--corPrincipal);
  border-color: var(--corPrincipal);
}

.css-1cm5myo {
  max-height: 100vh !important;
  max-width: 100vw !important;
}

.react-phone-number-input__row {
  margin-top: -2px;
}
.react-phone-number-input__input {
  border-bottom: none !important;
}
.react-phone-number-input__icon-image,
.react-phone-number-input__icon--international svg {
  vertical-align: top;
}
.navbar-light .navbar-toggler {
  border: none;
}
.navbar-light .navbar-toggler:after {
  font: normal normal normal 32px/1 FontAwesome;
  content: '\f0c9';
  color: var(--corPrincipal);
}
.navbar-light .navbar-toggler-icon {
  display: none;
}

.spinner-border {
  border: 0.25em solid var(--corAnalogaClara);
  border-right-color: transparent;
}

// Jivo chat
.wrap_cea5 {
  z-index: 999 !important;
}
