@import url('https://fonts.googleapis.com/css?family=Nova+Square&display=swap');
@import url('https://fonts.googleapis.com/css?family=Oxygen&display=swap');

/* VARIAVEIS */

:root {
  --corPrincipalRgba: rgb(0, 146, 145, 0.7);
  --corComplementarRgba: rgba(255, 201, 111, 0.7);
  --corAnalogaClaraRgba: rgb(0, 179, 202, 0.7);
  --corAnalogaEscura: #1c5c5a;
  --corNegativo: #bd2e15;
  --corNegativoClara: #ed5c42;
  --corFundoCinza: #eeeeee;
  --corFundoCinzaMed: #ddd;
  --corFundoCinzaEsq: #a7a7a7;
  --font-family-sans-serif: 'Nova Square', 'Segoe UI', Roboto, 'Helvetica Neue',
    Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
}
$enable-negative-margins: true;
$primary: #009291;
$body-bg: #fafafa;

html[data-theme='light'] {
  --corPrincipal: #009291;
  --corComplementar: #ffc96f;
  --corFundoBege: #fff7e7;
  --corAnalogaClara: #00b3ca;
}

html[data-theme='dark'] {
  --corPrincipal: #2f5852;
  --corComplementar: #d4b786;
  --corFundoBege: #333333;
  --bs-body-bg: #151413;
  --corAnalogaClara: #248b99;
}

@import '~bootstrap/scss/bootstrap';
@import './css/_reset';
@import './css/_icons';
@import './css/_buttons';

@import './css/darkmode';

//Custom utilies
.fs-xsmall {
  font-size: 0.875em;
}
.fs-xlarge {
  font-size: 4rem;
}
.fs-6 {
  font-size: 1.15rem !important;
}
.font-oxygen {
  font-family: 'Oxygen';
}

.text-cor-principal {
  color: var(--corPrincipal);
}
.text-cor-anlga {
  color: var(--corAnalogaClara);
}
.text-cor-anlga-esc {
  color: var(--corAnalogaEscura);
}
/* BASE LAYOUT */

body {
  font-family: var(--font-family-sans-serif);
  &.ReactModal__Body--open {
    overflow: hidden;
  }
}
h2 {
  text-transform: uppercase;
  color: var(--corPrincipal);
}

//verificar pois em nenhum lugar esta usando - inicio
.txtNaoLogado {
  font-size: small;
  opacity: 0.8;
}
.alerta-acord {
  border: none !important;
  padding: 0;
  margin-bottom: 10px;
  font-size: small;
  color: var(--corNegativoClara);
}
.alerta-modelador, //essa classe é a unica classe usada
.alerta-stls {
  padding: 0 0 5px 0;
  font-size: small;
  color: var(--corNegativoClara);
}
.alerta-acord-txt {
  border: none !important;
  padding: 0;
  margin-bottom: 15px;
  font-size: small;
}
//verificar pois em nenhum lugar esta usando - fim

.modalBuy {
  .modal-content {
    background: white url(/assets/img/bg-modal-buy.jpg) no-repeat left top;
    @media (min-width: 992px) {
      padding: 20px;
    }
  }
  .modal-header {
    border: none;
    padding-bottom: 20px;
    @media (min-width: 992px) {
      padding: 20px;
    }
  }
  .modal-body {
    padding-top: 0;
  }
  .modelTitle {
    font-weight: 700;
    margin-bottom: 5px;
  }
  .form-label {
    color: #666666;
    font-size: 14px;
    display: block;
    margin-bottom: 0;
  }
  .subtotal-with-field {
    line-height: 38px;
  }
  .summary-title {
    font-weight: 700;
  }
}

.bottom-separator {
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 16px;
  @media (min-width: 992px) {
    border-bottom: none;
    margin-bottom: 0;
  }
}
.col-payment {
  @media (min-width: 992px) {
    border-left: 1px solid #dee2e6;
    padding-left: 30px;
  }
}
.col-product {
  @media (min-width: 992px) {
    padding-right: 30px;
  }
}
.checkoutList {
  list-style: none;
  padding-left: 0;
  color: #666666;
  font-size: 14px;
  li {
    list-style: none;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
}
.listCredit {
  .checkoutValue {
    color: var(--corPrincipal);
  }
}
.listTotal {
  font-size: 16px;
  font-weight: 700;
  color: #212529;
}
.RadioPayment {
  display: flex;
  .form-check {
    padding-left: 0;
  }
  label {
    background: white;
    padding: 8px 13px 8px 33px;
    border-radius: 8px;
    border: 1px solid var(--corPrincipal);
    margin-right: 5px;
  }
}
.mixed-payment {
  font-size: 14px;
  color: #666;
}
.modal-max {
  .modal-dialog {
    max-width: 1550px;
  }
}

.nao-existe-modelo {
  font-size: x-large;
  i {
    font-size: 7rem;
    display: block;
    margin-bottom: 1rem;
  }
}

a {
  color: var(--corAnalogaEscura);
  text-decoration: none;
}
a:hover {
  color: var(--corPrincipal);
  text-decoration: none;
}

.co3dpartnerbg {
  position: absolute;
  opacity: 0.9;
  top: 0;
  right: 0;
  width: 0;
  height: 0;
  border-top: 90px solid white;
  border-left: 90px solid transparent;
  z-index: 2;
}
.co3dpartnertxt {
  position: absolute;
  font-size: small;
  top: 5px;
  right: 5px;
  color: var(--corPrincipal);
  text-align: right;
  z-index: 3;
}
.co3dpartnertxt img {
  width: 25px;
}
.rg-15 {
  right: 15px;
}
.div-vertodos {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--corAnalogaEscura);
  height: 90%;
}

.svgball {
  background-color: white;
  border-radius: 50%;
  padding: 20px;
  width: 130px;
  height: 130px;
  margin: auto;
  display: flex;
}
.svgball svg {
  margin: auto;
}
footer ul {
  list-style: none;
  padding: 0;
}
.alertaestorno {
  font-style: italic;
  font-size: x-small;
  opacity: 0.5;
  margin-top: 15px;
  @media (max-width: 576px) {
    line-height: 1;
  }
}

.fee-obs {
  font-size: 0.875rem;
  opacity: 0.5;
}
.react-paypal-modal-participa {
  margin-right: 0;
  width: 246px;
  .paypal-buttons-context-iframe {
    display: block !important;
  }
}

.model-pendente {
  opacity: 0.7;
}
.aprovacaotxt {
  color: var(--corNegativoClara);
  font-style: italic;
}

.slugalert,
.exclusivealert {
  color: var(--corNegativoClara);
  font-size: x-small;
  padding: 0;
}

/* PAGINAS */
.model-pending {
  color: var(--corNegativoClara);
}
.box-login {
  margin: 80px auto;
}
.social-login {
  margin: 20px 0;
}
.social-login a,
.nao-membro a,
.esqueci-senha a {
  color: var(--corAnalogaClara);
}

.crowdfunding-svg {
  margin: 20px 80px;
}
.escalaDestaque {
  color: var(--corNegativoClara);
}
.imgRefScales {
  margin-bottom: 15px !important;
}
.img-destaque {
  margin-bottom: 15px;
  cursor: zoom-in;
}
.img-thumb-carrosel {
  margin-bottom: 15px;
  cursor: zoom-in;
}
.b-inter {
  background-image: url(/assets/img/co-3d-banner-internas.jpg);
  font-size: xx-large;
  text-align: center;
  padding: 30px 0;
  margin-bottom: 30px;
  color: white;
}
table > tbody > tr > td:last-child,
table > thead > tr:last-child > th:last-child {
  position: sticky;
  position: -webkit-sticky;
  right: 0;
  z-index: 1;
  text-align: right;
}

.inside-table {
  background-color: #eeeeee;
  @media (min-width: 768px) {
    margin: 0 15px 15px 15px;
  }
  .table-responsive {
    margin: 0;
    padding: 10px;
    @media (min-width: 768px) {
      padding: 15px;
      td:first-child {
        width: auto;
      }
    }
    &:nth-child(even) {
      background-color: var(--corFundoCinzaMed);
    }
    td:last-child {
      background-color: transparent;
    }
    .col-valquota-proposal {
      @media (max-width: 768px) {
        width: 70px;
      }
    }
  }
}

.totals-perfil {
  border-top: 1px solid var(--corFundoCinza);
}
.perfil-h3 {
  font-size: large;
  text-align: center;
  background: #ffffff;
  margin: 0;
  padding: 15px 0;
  text-transform: uppercase;
}
.nome-modelador {
  cursor: pointer;
}
.nome-modelador:hover {
  color: var(--corPrincipal);
}
.listaajustes {
  background: var(--corFundoCinza);
  height: 200px;
  overflow: auto;
  padding: 20px;
  margin-bottom: 15px;
  li {
    span {
      display: inline-block;
      margin-left: 5px;
      vertical-align: top;
    }
  }
}
.listaajustes p {
  font-size: 12px;
  margin: 0;
}
.listaajustes b {
  margin-bottom: 10px;
  display: block;
}
.txtimport {
  color: var(--corNegativo);
  font-weight: bold;
  font-size: smaller;
  margin-bottom: 6px;
}
.txtobs {
  color: var(--corAnalogaClara);
}
.txtdestaque {
  color: var(--corPrincipal);
  font-weight: bold;
  font-size: large;
}
.item-works {
  text-align: center;
  margin: 30px 0;
}
.item-works div {
  background-color: var(--corPrincipal);
  width: 10vw;
  height: 10vw;
  border-radius: 50%;
  margin: auto;
  position: relative;
}

.item-works p {
  font-weight: bold;
  margin-top: 10px;
  font-size: large;
}

.infotip {
  margin-left: 5px;
}
.precotip {
  font-size: 12px;
  opacity: 0.7;
}
.works-aprova {
  padding: 20px;
  margin: 20px 0;
  border: 5px solid var(--corAnalogaClara);
}
.works-reprova {
  padding: 20px;
  margin: 20px 0;
  border: 5px solid var(--corNegativoClara);
}

.skew-fix {
  display: inline-block;
  transform: skew(20deg);
  color: white;
  text-transform: uppercase;
}
.skew-fix i {
  font-size: x-large;
  vertical-align: sub;
  margin-right: 6px;
}
.skew-fix .spinner-border {
  margin-left: 15px;
}
.obscard {
  margin: 10px 0 0 0;
  font-size: small;
  font-weight: bold;
  color: var(--corComplementar);
}
.card-faq {
  margin-bottom: 0;
}
.modelador-atrasado {
  color: var(--corNegativoClara);
}
.pagination {
  justify-content: center;
}
.fullscreen > div {
  z-index: 10000;
}

.gridHolder {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}

.cardBase {
  font-family: 'Oxygen';
  margin: 0 5px 32px 5px;
}
.cardHeader {
  position: relative;
  height: 300px;
}

.img-blur {
  -webkit-filter: blur(10px) !important;
  -moz-filter: blur(10px) !important;
  -o-filter: blur(10px) !important;
  -ms-filter: blur(10px) !important;
  filter: blur(10px) !important;
}

.img-card {
  opacity: 0;
  position: absolute;
  padding: 15px;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  z-index: 1;
  background-color: var(--corAnalogaClaraRgba);
  transition: all 0.3s ease;
  font-family: 'Oxygen';
}
.img-card:hover {
  opacity: 1;
  color: white !important;
}
.sensitive-content {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.sensitive-content p {
  color: white;
  text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.68);
  margin: 0;
}
.detail-img-holder {
  position: relative;
}
.detail-img-holder img {
  width: 100%;
}
.find-modeler-holder .flag-like-bg,
.find-modeler-holder .flag-like {
  bottom: 23px;
  right: 10px;
}
.contador {
  font-size: 16px;
  color: var(--corAnalogaClara);
  display: block;
}
.preco {
  display: block;
  font-size: 24px;
  color: var(--corPrincipal);
}
.likes {
  margin: 10px 0 0 0;
  color: var(--corAnalogaClara);
  display: block;
}
.cotaspagas {
  font-size: 14px;
  margin: 20px 0 5px 0;
  display: block;
}
.faixa-perfil {
  height: 90px;
}

.bg-branco {
  background-color: white;
}
.bg-bege {
  background-color: var(--corFundoBege);
}
.bg-amarelo {
  background-color: var(--corComplementar);
}
.bg-verde-azul {
  background: rgb(0, 179, 202);
  background: linear-gradient(
    90deg,
    rgba(0, 179, 202, 1) 0%,
    rgba(28, 92, 90, 1) 100%
  );
}
.reputacao {
  margin: 0 0 40px 0;
  font-size: medium;
}
.dropzone-img {
  background-color: var(--corFundoCinza);
  border: 1px dotted #333;
  padding: 30px 0;
  cursor: pointer;
}
.dropzone-img p {
  margin: 0;
  text-align: center;
}
.react-crop-img {
  width: 100%;
  display: flex;
  background: #eee;
  justify-content: center;
  align-items: center;
}

.react-crop-visu-img {
  float: left;
  margin: 20px 0 0 20px;
}
.react-crop-visu-img img {
  width: 300px;
  height: 300px;
}
.thumbInitial {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.thumbInitial:after {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.txt-ordenar {
  padding: 5px 0 0 0;
}

.asterisco {
  font-size: small;
  color: var(--corPrincipal);
}
.dir-quemsomos {
  width: 50%;
  float: right;
  padding: 30px;
}
.esq-quemsomos {
  width: 50%;
  float: left;
  background-repeat: no-repeat;
  background-color: #051010;
  background-image: url(/assets/img/quemsomos.jpg);
  background-position: center center;
  min-height: 150px;
}

@media (max-width: 768px) {
  .esq-quemsomos {
    background-size: cover;
  }

  .nav-item {
    font-size: small;
    .btn {
      font-size: small;
    }
    a {
      padding: 0.5rem;
    }
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
.navbar-expand-lg .navbar-nav .nav-link:last-child {
  padding-right: 0;
}
/* FILTROS DE BUSCAS */
@media (min-width: 992px) {
  .busca-all {
    max-width: 650px;
  }
}

.busca-all form {
  position: relative;
  box-sizing: border-box;
  .form-group {
    margin-bottom: 0;
  }
}
.busca-all input {
  border: 1px solid #ebebeb;
  border-radius: 2px;
  text-transform: uppercase;
}

//Ainda não consegui levar para modulo pois está fazendo o filtro parar de abrir
//parece ter relação com a ordem de carregamento

#page-content-wrapper {
  min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

#sidebar-wrapper {
  background-color: white;
  min-height: 100vh;
  margin-left: -15rem;
  -webkit-transition: margin 0.25s ease-out;
  -moz-transition: margin 0.25s ease-out;
  -o-transition: margin 0.25s ease-out;
  transition: margin 0.25s ease-out;
  position: sticky;
  top: 111px;
  @media (max-width: 768px) {
    top: 180px;
  }
}

#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#sidebar-wrapper .sidebar-subheading {
  padding: 0.875rem 0;
  font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
  padding: 0.875rem 1.25rem;
  width: 15rem;
}
@media (min-width: 768px) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: -15rem;
  }
}
.custom-control-input:checked ~ .custom-control-label::before {
  border-color: var(--corPrincipal);
  background-color: var(--corPrincipal);
}

.history-date-column {
  min-width: 180px;
}
.withdraw-description {
  color: #777;
}
.account-digit {
  width: 100px;
  flex: 0 1 auto;
}

/**
 * Multiple files list
 */
.multiple-files-list {
  margin-top: 5px;
}
.multiple-files-list span {
  display: inline-block;
  background: #ddd;
  border-radius: 3px;
  padding: 5px 10px;
  margin: 5px 5px 5px 0;
}
/**
 * Upload progress
 */
.upload-item {
  padding: 0 0 10px;
  p {
    margin: 0;
  }
}

.col-bt-down {
  width: 50px;
  text-align: center;
}

.dollar-observation-container {
  margin-top: 50px;
}
.dollar-observation {
  color: #bd2e15;
  font-size: small;
  text-align: center;
}
.flowmap {
  border-top: #eeeeee solid 1px;
  padding: 35px 0 30px 0;
}
