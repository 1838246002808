.btn-primary {
  color: #fff;
  &:hover,
  .btn-check:focus + &,
  &:focus,
  &:disabled,
  &.disabled {
    color: #fff;
  }
}
.btn-card {
  margin: 0 3px 0 3px;
  padding: 6px 8px;
}
.btn-card-n-pos {
  margin: 0 3px -28px 3px;
}
.checkout-buttons-wrap {
  div.btPayPalHolder {
    background: #ffc439;
    transform: skew(-20deg);
    overflow: hidden;
    width: 240px;

    > div {
      transform: skew(20deg);
    }
  }
}

@media (max-width: 700px) {
  .account-bts button,
  .account-bts a {
    width: 48%;
    margin: 1%;
  }
  .account-bts button span,
  .account-bts a span {
    margin: auto;
  }
}

/**
 * Paypal
 */
.paypal-button-custom-wrapper-disabled {
  opacity: 0.5;
}
.paypal-button-custom-wrapper-disabled:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

/**
 * Files table
 */
.download-button {
  background-color: var(--corAnalogaEscura);
  border: none;
}
.delete-file-button {
  border: none;
  i {
    margin-right: 5px;
  }
}

.linkNaoLogado,
.btLinkStyle {
  border: none;
  background: none;
  padding: 0;
  margin-bottom: 10px;
  color: var(--corPrincipal);
  text-decoration: underline;
  :hover {
    color: var(--corAnalogaClara);
  }
}
.btLinkStyle {
  margin: auto;
  text-decoration: none;
}
.btLinkStyle:hover {
  text-decoration: underline;
}

.dropdown > .btn-secondary {
  background: none;
  border: none;
  color: var(--corAnalogaClara);
}
.bt-vertodos {
  max-width: 50%;
  text-align: center;
}
.bt-vertodos i {
  background-color: var(--corAnalogaClara);
  border-radius: 50%;
  min-height: 100px;
  min-width: 100px;
  font-size: 50px;
  padding-top: 22px;
  color: white;
  margin-bottom: 10px;
}

.bt-facebook {
  background-color: #4c69ba;
  background-image: linear-gradient(#4c69ba, #3b55a0);
  /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
  text-shadow: 0 -1px 0 #354c8c;

  padding: 8px 15px;
  color: white !important;
  display: inline-block;
  width: 49%;
  margin-right: 2%;
}
.bt-google {
  background: #dd4b39;
  padding: 8px 15px;
  color: white !important;
  display: inline-block;
  width: 49%;
}
.bt-google .spinner-border,
.bt-facebook .spinner-border {
  border-color: #fff;
  border-right-color: transparent;
  float: right;
  margin-top: 4px;
}

.navbar-light .navbar-nav > a.btn-cart {
  color: var(--corPrincipal);
  text-align: center;
  min-width: auto;
}
@media (min-width: 992px) {
  .navbar-light .navbar-nav > a.btn-cart {
    font-size: large;
    padding: 9px 0;
    text-align: center;
  }
}
.navbar-light .navbar-nav > a.btn-cart:hover {
  color: var(--corPrincipal);
}
.btn-full {
  border-radius: 0;
  margin: 0;
  display: block;
  text-align: center;
  color: white;
  padding: 15px 0;
  font-size: large;
  border: none;
  background-color: var(--corAnalogaClara);
  transition: all 0.3s ease;
}
.btn-full:hover {
  background-color: var(--corAnalogaEscura);
  color: white;
}
@media (max-width: 1000px) {
  .bts-table-mob {
    display: flex;
    flex-direction: column;
    width: 110px;
  }
}

.btn-paralel {
  transform: skew(-20deg);
  border-radius: 0;
  background-color: var(--corPrincipal);
  border: none;
  padding: 10px 30px;
  min-width: 150px;
  display: table;
  transition: all 0.3s ease;
  font-family: var(--font-family-sans-serif);

  @media (max-width: 576px) {
    width: 93%;
    margin-right: auto;
    margin-left: auto;
  }
}
.btn-oneclick {
  @media (max-width: 576px) {
    width: 100%;
    font-size: 20px;
  }
}
.btn-paralel:hover,
.btn-paralel:focus,
.btn-paralel:active {
  background-color: var(--corAnalogaEscura) !important;
}
.btn-sw-ylw {
  -webkit-box-shadow: 3px 3px 0px 2px var(--corComplementar);
  box-shadow: 3px 3px 0px 2px var(--corComplementar);
  padding: 5px 20px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.bt-cota:not(:last-child) {
  margin-right: 15px;
}
.bt-cota {
  margin-bottom: 15px;
  display: block;
}
.bt-pay {
  margin-bottom: 15px;
  display: block;
  background-color: var(--corAnalogaClara);
}
.bt-paysaque {
  margin-bottom: 15px;
  display: block;
  padding: 15px 100px;
}

.bt-cotas,
.bt-saque,
.bt-paysaque {
  display: inline-flex;
}
.bt-saque {
  margin-right: 10px;
  background-color: var(--corAnalogaClara);
}
.bt-secundario {
  background-color: var(--corFundoCinzaEsq);
}

.bt-negativo {
  background-color: var(--corNegativo);
}
.bt-negativo:hover {
  background-color: red;
}

.bt-alerta {
  background-color: orange;
}

.bt-alerta:hover {
  background-color: var(--corComplementar);
}

.bt-comprar {
  background-color: var(--corAnalogaEscura);
}
.bt-azul {
  background-color: var(--corAnalogaClara);
  border: none;
}

.bt-amarelo {
  background-color: var(--corComplementar);
  > span {
    color: #333;
  }
  border: none;
  &:hover {
    > span {
      color: white;
    }
  }
}
.bt-aceitar {
  color: var(--corAnalogaClara);
  font-weight: bold;
  @include base-link-icon('\f00c');
}
.bt-entregar {
  color: var(--corAnalogaClara);
  font-weight: bold;
  @include base-link-icon('\f064');
}
.bt-enviararquivos {
  color: var(--corAnalogaClara);
  font-weight: bold;
  @include base-link-icon('\f093');
}

.bt-editar {
  color: var(--corPrincipal);
  font-weight: bold;
  @include base-link-icon('\f040');
}

.bt-proposta {
  color: var(--corPrincipal);
  font-weight: bold;
  @include base-link-icon('\f0ce');
}

.bt-download {
  color: var(--corAnalogaEscura);
  font-style: italic;
  font-weight: bold;
  @include base-link-icon('\f019');
}
.bt-detalhes {
  color: var(--corAnalogaEscura);
  font-style: italic;
  font-weight: bold;
  @include base-link-icon('\f15c');
}
.bt-recusar {
  color: var(--corNegativo);
  font-weight: bold;
  @include base-link-icon('\f00d');
}
.bt-recusar:hover {
  color: red;
}

.busca-all .btn-enviar-all {
  position: absolute;
  right: 10px;
  top: 0;
  background-color: transparent !important;
  border: none !important;
}

.busca-all .btn-enviar-all:before {
  display: block;
  position: absolute;
  right: 0px;
  top: 5px;
  color: var(--corPrincipal);
  font: normal normal normal 27px/1 FontAwesome;
  content: '\F002';
}

.bt-filtro,
.bt-filtro:hover,
.bt-filtro:focus {
  background-color: transparent !important;
  border: none !important;
  color: var(--corPrincipal) !important;
  padding: 0;
  font-size: 24px;
  margin-left: 17px;
}

.custom-button-group {
  display: inline;
  .btn-primary {
    outline: none;
    opacity: 0.4;
    border: none;
    margin: 0 5px 5px 0;

    &:active,
    &:focus {
      opacity: 1 !important;
      color: white;
      border: none;
      outline: none !important;
    }
  }
  .btn-check:checked + .btn-primary {
    color: white;
    opacity: 1 !important;
  }
}
