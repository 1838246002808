.modalNotif :global div.modal-footer,
.modalNotif :global div.modal-header {
  border-top: none;
  border-bottom: none;
}
.modalNotifTit {
  display: flex;
  margin-bottom: 1rem;
  img {
    margin: 0 20px 0 0;
  }
  & > div {
    display: flex;
    align-items: center;
  }
  div {
    p {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.modalNotif {
  :global .bt-secundario {
    &:hover {
      background-color: #bd2e15 !important;
    }
  }
}

@media (max-width: 992px) {
  .modalNotif {
    :global .h4 {
      font-size: 1.1em;
    }
    p {
      font-size: small;
    }
  }
  .modalNotifTit {
    display: block;
    text-align: center;
    img {
      margin: 30px;
    }
  }
}
