.cookieConsetPopup {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 3000;
}

@media (min-width: 768px) {
  .cookieConsetPopup {
    :global .toast-body {
      display: flex;
      align-items: center;
      :global p {
        margin-bottom: 0;
      }
    }
  }
}
