//@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css');

@mixin base-link-icon($icon) {
  &::before {
    font: normal normal normal 16px/1 FontAwesome;
    content: $icon;
    margin-right: 5px;
  }
}

.iconaddrequest {
  fill: var(--corComplementar);
}
.iconaddmodel {
  fill: var(--corAnalogaClara);
}
.iconaddmkt1 {
  fill: var(--corAnalogaClara);
}
.iconaddmkt2 {
  fill: var(--corAnalogaEscura);
}
.iconaddmkt3 {
  fill: var(--corPrincipal);
}

.customicon-discord::before {
  content: '';
  background: url(/assets/img/icon-discord.svg);
  height: 16px;
  width: 20px;
  display: block;
  background-size: cover;
}
.iconRequest::before {
  content: '';
  display: block;
  background-image: url(/assets/img/co3d-pedido.svg);
  height: 25px;
  width: 25px;
}
.iconCart {
  i {
    font-size: 1.8rem;
    color: var(--corPrincipal);
  }
}
.icone-model,
.icone-vaca,
.icone-pedido,
.icone-finalizado {
  padding: 3px 10px;
  color: white;
  z-index: 1;
  position: relative;
}

.icone-pedido {
  background-color: var(--corComplementar);
}
.icone-vaca {
  background-color: var(--corAnalogaClara);
}
.icone-model {
  background-color: var(--corPrincipal);
}
.icone-finalizado {
  background-color: var(--corPrincipal);
  border-top: 2px solid var(--corComplementar);
}
.icone-onebuy,
.icone-like,
.icone-campanha,
.icone-desconto,
.icone-pedido i,
.icone-vaca i,
.icone-model i,
.icone-finalizado i,
.icone-pedido-fill i,
.icone-vaca-fill i,
.icone-model-fill i,
.icone-finalizado-fill i {
  background-position: center center;
  background-size: cover;
  display: inline-flex;
  vertical-align: middle;
  width: 23px;
  height: 23px;
}
.icone-pedido-fill i,
.icone-vaca-fill i,
.icone-model-fill i,
.icone-finalizado-fill i {
  border-radius: 50%;
  background-repeat: no-repeat;
  background-size: 65%;
  width: 35px;
  height: 35px;
}
.icone-onebuy {
  background-image: url(/assets/img/one-click.svg);
  width: 20px;
  height: 20px;
}
.icone-campanha {
  background-image: url(/assets/img/icon-campanhas.svg);
  width: 78px;
  height: 81px;
}

.icone-desconto {
  background-image: url(/assets/img/icon-desconto.svg);
  width: 68px;
  height: 82px;
}
.icone-like {
  background-image: url(/assets/img/icon-like.svg);
  width: 20px;
  height: 20px;
  margin: -5px 2px 0 2px;
}
.icone-pedido-fill i {
  background-color: var(--corComplementar);
}
.icone-vaca-fill i {
  background-color: var(--corAnalogaClaraRgba);
}
.icone-model-fill i {
  background-color: var(--corPrincipalRgba);
}
.icone-finalizado-fill i {
  background-color: var(--corPrincipalRgba);
  border-top: 2px solid var(--corComplementar);
}
.icone-pedido i,
.icone-pedido-fill i {
  background-image: url(/assets/img/co3d-pedido.svg);
}
.icone-vaca i,
.icone-vaca-fill i {
  background-image: url(/assets/img/co3d-vaquinha.svg);
}
.icone-model i,
.icone-model-fill i {
  background-image: url(/assets/img/co3d-modelando.svg);
}
.icone-finalizado i,
.icone-finalizado-fill i {
  background-image: url(/assets/img/co3d-mktpalce.svg);
}

.iw-pedido {
  background-color: var(--corComplementar) !important;
  background-image: url(/assets/img/co3d-pedido.svg);
}

.iw-vaquinha {
  background: url(/assets/img/co3d-vaquinha.svg);
}
.iw-modelagem {
  background-color: var(--corAnalogaClara) !important;
  background-image: url(/assets/img/co3d-modelando.svg);
}
.iw-finalizado {
  background-color: var(--corAnalogaEscura);
  border: 5px solid var(--corComplementar);
  background-image: url(/assets/img/co3d-mktpalce.svg);
}

.iw-pedido,
.iw-vaquinha,
.iw-modelagem,
.iw-finalizado {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 50%;
}

@media (max-width: 500px) {
  .iw-pedido,
  .iw-vaquinha,
  .iw-modelagem,
  .iw-finalizado {
    background-size: 60%;
  }
  .iw-finalizado {
    border: 2px solid var(--corComplementar);
  }
}

.curtir::after {
  content: '\f087';
}

.descurtir::after {
  content: '\f088';
}
