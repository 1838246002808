html[data-theme='dark'] {
  $bodycolor: #c5bcab;
  $primary: #2f5852;

  body {
    color: $bodycolor;
  }
  .text-dark {
    color: $bodycolor !important;
  }
  .modal-content {
    background-color: var(--bs-body-bg) !important;
  }
  .bg-light {
    background-color: var(--bs-body-bg) !important;
  }

  .list-totals {
    background-color: var(--bs-body-bg) !important;
    border: 1px solid #333 !important;
  }
  .card {
    border-color: #333;
    background-color: #1a1a1a;
  }
  .bg-amarelo {
    .card {
      border-color: #9b8258;
      background-color: var(--corComplementar);
    }
    .card-text {
      color: var(--bs-body-color);
    }
  }
  .dropdown-menu,
  .PhoneInputInput,
  .form-control,
  .form-select,
  .form-check-input,
  .form-radio-input,
  .input-group-text,
  .form-control:focus,
  .dropzone-img,
  .accordion-button,
  .accordion-item {
    background-color: rgb(21, 20, 19) !important;
    border-color: rgb(48, 48, 45) !important;
    color: $bodycolor;
  }
  .accordion-button:not(.collapsed) {
    background-color: var(--corFundoBege);
    color: $bodycolor;
  }
  .dropdown-item:hover {
    background-color: var(--corFundoBege);
  }
  a {
    color: #b0c3b4 !important;
  }
  img {
    filter: brightness(0.8) contrast(1.2);
  }
  #sidebar-wrapper {
    background-color: #051010 !important;
    border-color: #333 !important;
  }
  .bt-amarelo {
    background-color: var(--corComplementar);
    > span {
      color: #333 !important;
    }
    &:hover {
      > span {
        color: white !important;
      }
    }
  }
  .bt-azul {
    > span {
      color: white !important;
    }
  }
  .bt-filtro {
    color: #b0c3b4 !important;
  }
  .btn-paralel,
  .skew-fix {
    color: $bodycolor !important;
  }
  .bt-facebook,
  .bt-google,
  .b-inter,
  .iconRequest::before {
    opacity: 0.7;
  }
  .table {
    color: $bodycolor;
    border-color: #333;
  }
  .bt-secundario {
    background-color: #333;
  }
  .listaajustes,
  .svgball,
  .toast,
  .inside-table,
  .perfil-h3 {
    background-color: #051010 !important;
  }
  .inside-table .table-responsive:nth-child(2n) {
    background-color: #112b2b !important;
  }

  .page-item.disabled .page-link {
    background-color: #051010;
    border-color: #333;
  }
  .page-link {
    background-color: var(--bs-body-bg);
  }

  .modalBuy {
    .modal-content {
      background: var(--bs-body-bg) url(/assets/img/bg-modal-buy-dark.jpg)
        no-repeat left top;
    }
  }
  .RadioPayment {
    label {
      background-color: #d1d1d1;
    }
  }
  .col-payment {
    @media (min-width: 992px) {
      border-left: 1px solid #333;
    }
  }
  .listTotal {
    color: white;
  }
  .btn-close {
    background-color: $bodycolor;
  }
}
